








































































































































































import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  toRefs
} from "@vue/composition-api"

import type { PropType } from "@vue/composition-api"

import User from "@shared/User"

import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import MissionCard from "@/components/GroupTeams/Common/Games/GameCardParts/MissionCard.vue"
import BigUglyButton from "../../BuzzIn/BigUglyButton.vue"
import JeopardAiUsersGrid from "./JeopardAiUsersGrid.vue"
import JeopardAiWager from "./JeopardAiWager.vue"
import JeopardAiChallenge from "./JeopardAiChallenge.vue"
import JeopardAiTitle from "./JeopardAiTitle.vue"
import JeopardAiQuestionText from "./JeopardAiQuestionText.vue"
import JeopardAiTimer from "./JeopardAiTimer.vue"
import JeopardAiPrice from "./JeopardAiPrice.vue"
import JeopardAiLabel from "./JeopardAiLabel.vue"
import JeopardAiHint from "./JeopardAiHint.vue"

import useJeopardAiQuestionUI from "../use/useJeopardAiQuestionUI"

import { AnswersMode } from "../enums"

import type { Question, State } from "../types"
import { BUZZ_BUTTON_DELAY, INPUT_TYPES } from "../constants"
import { KEY_SPACE } from "keycode-js"

export default defineComponent({
  name: "JeopardAiQuestion",
  components: {
    AppUserCard,
    MissionCard,
    ResizableText,
    BigUglyButton,
    JeopardAiUsersGrid,
    JeopardAiWager,
    JeopardAiChallenge,
    JeopardAiPrice,
    JeopardAiLabel,
    JeopardAiHint,
    JeopardAiTimer,
    JeopardAiQuestionText,
    JeopardAiTitle
  },
  props: {
    drag: {
      type: Number,
      required: true
    },
    mode: {
      type: String as PropType<AnswersMode>,
      required: false,
      default: AnswersMode.NORMAL
    },
    state: {
      type: Object as PropType<State>,
      required: true
    },
    question: {
      type: Object as PropType<Question>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { state, drag } = toRefs(props)
    const {
      representative,
      answers,
      verify,
      amount,
      min,
      challenger,
      challenge,
      finale,
      viewer,
      dailyDoubleTeam,
      count,
      self,
      hasQueue,
      hasAnswers,
      answer,
      submit,
      regenerate,
      players,
      button,
      queue,
      score,
      fail,
      complete,
      reset,
      isViewerHostLike,
      wager,
      wagering,
      isQuestionVisible,
      predefined,
      addCommasToNumber,
      hasPlayers,
      time,
      unverified,
      incomplete,
      double,
      submitWagerAmount,
      submitWagerAnswer
    } = useJeopardAiQuestionUI(props)

    const interval = setInterval(() => {
      time.value = Date.now()
    }, 5000)

    const listeners: Function[] = []

    function handleKeyPress(event) {
      if (INPUT_TYPES.includes(event.target?.type)) return

      if (button.value === true && representative.value != null) return
      if (button.value === false) return
      if (count.value > -1) return

      if (event.keyCode === KEY_SPACE && !dailyDoubleTeam.value) {
        submit()
        return
      }
    }

    onBeforeUnmount(() => {
      clearInterval(interval)
      listeners.forEach(unlisten => unlisten())
    })

    onMounted(() => {
      const timestamp = state.value.timestamp

      if (
        timestamp != null &&
        double.value === false &&
        finale.value === false
      ) {
        const now = Date.now()
        const delay = BUZZ_BUTTON_DELAY + drag.value
        const diff = timestamp + delay - now
        const n = Math.floor(diff / 1000)
        if (n > 0) {
          count.value = n
          const interval = setInterval(() => {
            if (count.value === 0) clearInterval(interval)
            count.value = count.value - 1
          }, 1000)
        }
      }

      if (isViewerHostLike.value === false && viewer.value.role === "player") {
        listeners.push(() =>
          document.removeEventListener("keydown", handleKeyPress)
        )
        document.addEventListener("keydown", handleKeyPress)
      }
    })

    function back() {
      emit("navigateToPreviusStep")
    }

    return {
      back,
      submitWagerAnswer,
      submitWagerAmount,
      getShortenedName: User.getShortenedName,
      answers,
      verify,
      amount,
      min,
      challenger,
      challenge,
      finale,
      viewer,
      dailyDoubleTeam,
      count,
      self,
      hasQueue,
      hasAnswers,
      answer,
      submit,
      regenerate,
      players,
      button,
      queue,
      score,
      fail,
      complete,
      reset,
      isViewerHostLike,
      wager,
      isQuestionVisible,
      predefined,
      addCommasToNumber,
      unverified,
      incomplete,
      hasPlayers,
      representative,
      wagering
    }
  }
})
