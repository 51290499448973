



















import { defineComponent } from "@vue/composition-api"
import type { PropType } from "@vue/composition-api"
import { WeveTextarea } from "@weve/ui"

import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import JeopardAiChallengeForm from "./JeopardAiChallengeForm.vue"

import { QueueItem, JeopardAiUser, Question } from "../types"
import { UserStatus } from "../enums"

export default defineComponent({
  name: "JeopardAiChallenge",
  components: {
    AppUserCard,
    WeveTextarea,
    JeopardAiStars: () => import("./JeopardAiStars.vue"),
    JeopardAiChallengeForm
  },
  props: {
    question: {
      type: Object as PropType<Question>,
      required: true
    },
    item: {
      type: Object as PropType<QueueItem>,
      required: true
    },
    challenger: {
      type: Object as PropType<JeopardAiUser>,
      required: true
    }
  },
  setup() {
    return { UserStatus }
  }
})
