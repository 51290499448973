var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jeopardai-challenge" },
    [
      _c("JeopardAiChallengeForm", {
        attrs: {
          item: _vm.item,
          question: _vm.question,
          challenger: _vm.challenger,
        },
      }),
      _c(
        "div",
        { staticClass: "jeopardai-challenge__user ml-3" },
        [
          _vm.challenger.status === _vm.UserStatus.CORRECT
            ? _c("JeopardAiStars", {
                staticClass: "jeopardai-challenge__stars",
                attrs: { size: 300 },
              })
            : _vm._e(),
          _c("AppUserCard", { attrs: { user: _vm.challenger } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }