var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jeopardai-question" },
    [
      _c(
        "MissionCard",
        { staticClass: "jeopardai-question__card" },
        [
          _c(
            "div",
            { staticClass: "jeopardai-question__title" },
            [
              _c("JeopardAiTitle", { attrs: { value: _vm.question.category } }),
              !_vm.finale
                ? _c("JeopardAiPrice", {
                    staticClass: "ml-3",
                    attrs: { amount: _vm.amount },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.isQuestionVisible
            ? _c("JeopardAiQuestionText", {
                staticClass: "mt-1",
                attrs: { value: _vm.question.text },
              })
            : _vm.finale
            ? _c("JeopardAiLabel", { staticClass: "mt-4" }, [_vm._v(" FINAL ")])
            : _vm.question.double
            ? _c("JeopardAiLabel", { staticClass: "mt-4" }, [
                _vm._v(" DAILY DOUBLE "),
              ])
            : _vm._e(),
          _vm.button &&
          (_vm.dailyDoubleTeam == null ||
            _vm.dailyDoubleTeam.id === _vm.viewer.teamID)
            ? _c("JeopardAiHint", { staticClass: "mt-4" }, [
                _vm._v(" Buzz in to represent your team "),
              ])
            : _vm.answer
            ? _c("JeopardAiHint", { staticClass: "mt-4" }, [
                _vm._v(" " + _vm._s(_vm.answer) + " "),
              ])
            : _vm._e(),
          _vm.isViewerHostLike
            ? _c(
                "div",
                { staticClass: "jeopardai-question__controls mt-3" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "jeopardai-question__controls-btn jeopardai-question__controls-btn--back mr-3",
                      on: { click: _vm.back },
                    },
                    [
                      _c("i", { staticClass: "fas fa-circle" }),
                      _c("i", {
                        staticClass:
                          "fas fa-chevron-left jeopardai-question__controls-btn-inner mr-3",
                        staticStyle: { color: "#000" },
                      }),
                    ]
                  ),
                  _vm.isQuestionVisible && !_vm.predefined
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "jeopardai-question__controls-btn jeopardai-question__controls-btn--update mr-3",
                          on: {
                            click: function ($event) {
                              return _vm.regenerate()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fas fa-circle" }),
                          _c("i", {
                            staticClass:
                              "mdi mdi-swap-horizontal-bold jeopardai-question__controls-btn-inner mr-3",
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm.unverified
                    ? _c("i", {
                        staticClass:
                          "fas fa-check-circle jeopardai-question__controls-btn",
                        on: {
                          click: function ($event) {
                            return _vm.verify()
                          },
                        },
                      })
                    : _vm._e(),
                  (_vm.incomplete || _vm.challenger != null) && _vm.hasQueue
                    ? [
                        _c("i", {
                          staticClass:
                            "fas fa-check-circle jeopardai-question__controls-btn jeopardai-question__controls-btn--correct mr-3",
                          on: {
                            click: function ($event) {
                              return _vm.score()
                            },
                          },
                        }),
                        _c("i", {
                          staticClass:
                            "fas fa-times-circle jeopardai-question__controls-btn jeopardai-question__controls-btn--wrong mr-3",
                          on: {
                            click: function ($event) {
                              return _vm.fail()
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                  _vm.hasQueue || _vm.hasAnswers
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "jeopardai-question__controls-btn jeopardai-question__controls-btn--reset",
                          on: {
                            click: function ($event) {
                              return _vm.reset()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fas fa-circle" }),
                          _c("i", {
                            staticClass:
                              "fas fa-undo jeopardai-question__controls-btn-inner",
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "jeopardai-question__transition", mode: "out-in" } },
        [
          _vm.unverified && _vm.isViewerHostLike
            ? _c("div", { staticClass: "jeopardai-question__priority-info" }, [
                _vm._v(" You have to verify the final question. "),
              ])
            : _vm._e(),
          _vm.challenge != null && _vm.challenger != null
            ? _c("JeopardAiChallenge", {
                key: _vm.challenge.id,
                staticClass: "jeopardai-question__challenge ml-3",
                attrs: {
                  item: _vm.challenge,
                  question: _vm.question,
                  challenger: _vm.challenger,
                },
              })
            : _vm.dailyDoubleTeam == null
            ? _c(
                "div",
                {
                  key: "featured",
                  staticClass: "jeopardai-question__featured",
                },
                [
                  _vm.count > -1 && !_vm.finale
                    ? _c("JeopardAiTimer", [
                        _vm._v(" " + _vm._s(_vm.count) + " "),
                      ])
                    : [
                        _vm.hasPlayers
                          ? _c("JeopardAiUsersGrid", {
                              attrs: {
                                users: _vm.players,
                                question: _vm.question,
                              },
                            })
                          : _vm._e(),
                        _vm.button && _vm.representative
                          ? _c(
                              "BigUglyButton",
                              {
                                staticClass: "jeopardai-question__button",
                                class:
                                  ((_obj = {}),
                                  (_obj[
                                    "jeopardai-question__button--secondary"
                                  ] = _vm.hasPlayers),
                                  (_obj[
                                    "jeopardai-question__button--disabled"
                                  ] = true),
                                  _obj),
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "jeopardai-question__button-hint",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getShortenedName(
                                            _vm.representative
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm.button
                          ? _c(
                              "BigUglyButton",
                              {
                                staticClass: "jeopardai-question__button",
                                class: _vm.hasPlayers
                                  ? "jeopardai-question__button--secondary"
                                  : null,
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.submit()
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "jeopardai-question__button-hint",
                                  },
                                  [_vm._v("Hit Spacebar")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                ],
                2
              )
            : _c(
                "div",
                { key: "section", staticClass: "jeopardai-question__section" },
                [
                  _vm.hasPlayers
                    ? _c("JeopardAiUsersGrid", {
                        attrs: { users: _vm.players, question: _vm.question },
                      })
                    : _vm.dailyDoubleTeam &&
                      _vm.dailyDoubleTeam.id !== _vm.viewer.teamID
                    ? _c(
                        "div",
                        { staticClass: "jeopardai-question__priority-info" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.dailyDoubleTeam.name + " only!") +
                              " "
                          ),
                        ]
                      )
                    : _vm.wagering && _vm.representative
                    ? _c(
                        "div",
                        { staticClass: "jeopardai-question__priority-info" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getShortenedName(_vm.representative)) +
                              " is the scribe and is wagering! "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
      _vm.wager
        ? _c("JeopardAiWager", {
            staticClass: "jeopardai-question__wager",
            attrs: {
              finale: _vm.finale,
              question: _vm.state.finale,
              user: _vm.self,
              min: _vm.min,
            },
            on: {
              submitWagerAmount: _vm.submitWagerAmount,
              submitWagerAnswer: _vm.submitWagerAnswer,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }